.schedule-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 10px;
}

.day-column {
    background-color: #b27c6f;
}

.item-classes {
    text-align: center;
}

.day-title {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
    background: white;
    padding-top: 30px;
}

.hours {
    color: white;
    font-weight: 700;
    padding-top: 20px;
    font-size: 14px;
}

.dance-style {
    color: white;
    padding-bottom: 20px;
    font-size: 14px;
}

@media only screen and (max-width: 1200px) {
    .dance-style {
        font-size: 12px;
    }

    .hours {
        font-size: 12px;
    }
}
    
@media only screen and (max-width: 1024px) {
    .schedule-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .dance-style {
        font-size: 16px;
    }

    .hours {
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .schedule-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
