.TeachersPage {
  background: var(--color-background);
}

.TeachersPage h4 {
  font-weight: 600;
}

.Teachers-video {
  width: 400px;
  height: 100%;
  max-width: 100%;
}
