.Homepage-hero .Homepage-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Homepage-hero .Homepage-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Homepage-hero {
  min-height: 100vh;
  position: relative;
  margin-top: calc(-1 * var(--height-header));
}

.Homepage-hero-slogan {
  color: var(--color-rose);
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: var(--height-header);
}

.Homepage-block h2 {
  color: var(--color-rose);
  padding-bottom: 50px;
}

.Homepage-callAction {
  height: 300px;
  background-color: var(--color-rose);
  display: flex;
  align-items: center;
}

.Homepage-callAction a {
  text-decoration: none;
  color: var(--color-background);
}

.Homepage-icon-right {
  width: 45px;
  height: 45px;
  fill: var(--color-background);
}
