.MyGoals {
  flex-grow: 1;
}

.MyGoals-item {
  background-color: var(--color-background);
  color: var(--color-rose);
}

.MyGoals-plan {
  white-space: pre-wrap;
}
