:root {
  --color-rose: #f6aa91;
  --color-orange: #ea5e53;
  --color-background: #f6f2f0;
  --color-background-input: rgba(246, 242, 240, 0.4);
  --height-header: 100px;
  --height-footer: 120px;

  /* don't use scroll behavior smooth (default from bootstrap)  */
  scroll-behavior: auto;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Internacional", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: var(--color-rose);
  color: white;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

h1 {
  font-size: 80px;
  line-height: 100px;
  letter-spacing: 0.1em;
}

h2 {
  font-size: 48px;
  line-height: 68px;
  letter-spacing: 0.04em;
}

h3 {
  font-size: 36px;
  line-height: 49px;
  letter-spacing: 0.04em;
}

h4 {
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.04em;
}

h5 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 60px;
    line-height: 1.2;
  }

  h2 {
    font-size: 40px;
    line-height: 1.2;
  }
  
  h3 {
    font-size: 34px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 500px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
    line-height: 1.2;
  }
  
  h3 {
    font-size: 28px;
    line-height: 1.2;
  }
}

img {
  max-width: 100%;
}

h1.text-rose,
h2.text-rose,
h3.text-rose,
h4.text-rose,
h5.text-rose,
p.text-rose {
  color: var(--color-rose);
}

h1.text-orange,
h2.text-orange,
h3.text-orange,
h4.text-orange,
h5.text-orange,
p.text-orange {
  color: var(--color-orange);
}

.pt-header {
  padding-top: var(--height-header) !important;
}

.pb-header {
  padding-bottom: var(--height-header) !important;
}

.styled-link {
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  color: var(--color-rose);
  transition: all 0.3s ease-in-out;
}

.styled-link:hover {
  color: var(--color-orange);
}

.styled-link-dark {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: var(--color-orange);
  transition: all 0.3s ease-in-out;
}

.styled-link-dark:hover {
  color: var(--color-rose);
}

.container-xxl {
  padding-left: 5%;
  padding-right: 5%;
}

.button-primary {
  background-color: var(--color-rose);
  color: var(--color-background);
  border: none;
  padding: 6px 16px;
  transition: background-color 0.3s ease-in-out;
}

.button-primary:hover {
  background-color: var(--color-orange);
}

.button-secondary {
  background-color: var(--color-background);
  color: var(--color-rose);
  border: none;
  padding: 6px 16px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 0%;
}

.button-secondary:hover {
  background-color: var(--color-orange);
  color: var(--color-background);
}

.centered-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-message {
  color: var(--color-orange);
  font-size: 12px;
  line-height: 15px;
  min-height: 15px;
}

.form-label {
  color: var(--color-rose);
}

.form-control,
.form-select {
  background-color: var(--color-background-input);
  border-radius: 0;
  border: none;
  transition: background-color 0.3s ease-in-out;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
  background-color: rgba(246, 242, 240, 0.8);
}

.form-control:hover,
.form-select:hover {
  box-shadow: none;
  background-color: rgba(246, 242, 240, 0.8);
}

.form-control::-webkit-file-upload-button {
  background-color: var(--color-rose);
  border: 0;
  transition: background-color 0.3s ease-in-out;
  color: var(--color-background);
}

.form-control:hover::-webkit-file-upload-button {
  background-color: var(--color-orange) !important;
}

.social-link {
  font-size: 40px;
  color: var(--color-orange);
  transition: color 0.3s ease-in-out;
}

.social-link:hover {
  color: var(--color-rose);
}
