.teachersThumb {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-start;
}

.teacherPic {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.teacherName {
  align-self: center;
  margin-left: 50px;
}
