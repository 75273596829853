.Navbar-nav {
  background-color: rgba(246, 242, 240, 0.5);
  height: var(--height-header);
  font-weight: 400;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 3;
}

.Navbar-nav .btn:focus {
  box-shadow: none;
}

.Navbar-nav .dropdown-menu {
  border-radius: 0;
  border: none;
  background-color: var(--color-background);
  padding: 0;
}

.Navbar-nav .dropdown-menu .dropdown-item {
  padding: 10px 16px;
}

.Navbar-nav .dropdown-menu .dropdown-item:hover {
  color: var(--color-background);
  background-color: var(--color-rose);
}

.Navbar-nav .container-xxl {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  position: relative;
}

.Navbar-nav .Navbar-nav-left {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  padding-right: 120px;
}

.Navbar-nav img {
  /* height: 36px; */
  max-width: 100%;
}

.Navbar-nav .Navbar-nav-center {
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Navbar-nav .Navbar-nav-right {
  flex: 0 0 50%;
  padding-left: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Navbar-nav .Navbar-nav-link {
  text-decoration: none;
  color: #f5a48c;
  transition: all 0.3s ease-in-out;
  padding: 0.375rem 0.75rem;
}

.Navbar-nav .Navbar-nav-link:hover {
  text-decoration: none;
  color: #ea5e53;
}

.Navbar-nav-avatar {
  position: relative;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.Navbar-nav-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
