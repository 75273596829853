.Competition-page {
  background-color: var(--color-rose);
}

.Competition-hero-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Competition-hero {
  color: var(--color-background);
}

.Competition-hero h1 {
  font-size: 60px;
}

.Competition-hero h4 {
  max-width: 600px;
}

.Competition-carousel .carousel .slide iframe {
  margin: 0 10px;
  width: calc(100% - 20px);
}

@media only screen and (max-width: 900px) {
  .Competition-hero h1 {
    font-size: 40px;
    line-height: 1.2;
  }
}